import React from 'react'

export default function HomePage() {
  return (
    <div className='p-4 bg-slate-50 h-screen font-mono'>
        <div className=''>
            {/* <p className='text-3xl text-blue-800'>I share what I know here</p> */}
            <p className='text-2xl mt-2 text-gray-600'>Hi my name is Pavan Gaikwad and this is my web page on the internet.</p>    
            <p className='text-lg mt-4 text-gray-600'>I’ve been a full stack developer and been implementing DevOps for the last 12 years.</p>
            <p className='text-sm text-gray-600 mt-4'>My <a className='italic underline text-blue-700 font-bold' href="https://www.linkedin.com/in/pavangaikwad/">LinkedIn</a></p>
            <p className='text-sm mt-4 text-gray-600'>I write a personal blog/newsletters at <span className='italic underline text-blue-700 font-bold'><a href="https://pavansblog.substack.com">Pavan's Blog</a></span>.</p>
            <p className='text-sm mt-1 text-gray-600'>I make music - <a className='italic underline text-blue-700 font-bold' href="https://open.spotify.com/playlist/1UuQVUVB4LQaWasb8vUMiX?si=19e5a4fdb1c74d47">this playlist</a> has all the music I have released.</p>

            

            
            
        </div>
    </div>
  )
}
