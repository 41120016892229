import React from 'react'
import {Helmet} from 'react-helmet';

export default function WorkGrowth() {
  return (
    <div className='p-4 bg-slate-50 h-screen font-mono '>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Tactical playbook to grow with a day job [opiniated]</title>
                <link rel="canonical" href="https://pavan.contact/post/work-growth-playbook" />
                <link rel="description" href="How to grow while having a day job? I don't know for sure, but I have some ideas to explore." />
        </Helmet>

        <p className='text-3xl'>Tactical playbook to grow while having a day job [opiniated]</p>
        <p className='text-xs text-gray-500'>2022</p>

        <div className='mt-4'>
            <p className='text-gray-800 text-sm'>
            1. Get off all social media. ALL. You can get back later only to show your work and ideas - never discuss other people or events on social media.<br /><br />
            1.1 Find what your personal values are. You can start with <a href="https://personalvalu.es/" className='underline italic' target="_blank"> something basic like this</a>. Your values will help you self-navigate.<br/><br/>
2. Schedule 1 hour every day to take care of your body and health. Exercise, Skin Care, Yoga, etc. This is non-negotiable. If you have a day where you think you do not have time for health, you need to say “no” to some commitment and make time.<br /><br />
3. Schedule 2 hours for growth - deep learning and doing. You can pick 2 things to start off with. So let’s say you want to do product management and fiction writing. <br /><br />
4. Start building a product and writing fiction in these 2 hours. Every day. Just start. Whenever you are stuck, turn to the internet to find the best resources to help you proceed. DO NOT ask other people on social media, high chance they are as clueless as you. If there are people you respect, have a proper conversation with them and understand their views. Learn the fundamentals - find the “bibles” books of your craft. Invest here. Fundamentals will take you far.<br /><br />
5. It is important you have feedback loops in whatever you are doing. So show your product prototypes and fiction writings to people - ideally IRL and get feedback. <br /><br />
6. You can start documenting what you learn but avoid starting a newsletter yet. You can do that later.<br /><br />
7. Do whatever you want with the rest of the time. Day job, hanging out with friends, TV shows, dating, shopping, usual.<br /><br />
8. Sleep well.<br /><br />
<br />
            </p>
            </div>
            
    </div>
  )
}
