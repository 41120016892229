import React from 'react'
import {Helmet} from 'react-helmet';

export default function MyProductivitySystem() {
  return (
    <div className='p-4 bg-slate-50 h-screen font-mono '>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Simple, Free Productivity System</title>
                <link rel="canonical" href="https://pavan.contact/post/productivity-system" />
                <link rel="description" href="I have spent years trying to find the best set of tools for managing my everyday life. What worked is a simple system which I share in this post." />
        </Helmet>

        <p className='text-3xl'>My Extremely Simple and Free Productivity System</p>
        <p className='text-xs text-gray-500'>2022</p>

        <div className='mt-4'>
            <p className='text-gray-800 text-sm'>
            I have spent years trying to find the best set of tools for managing my everyday life.
I essentially needed tools to manage my notes, planning and tracking personal and work projects and daily chores. Reminders were required too. Then I also needed a safe tool to save my journals.
            </p>
            <p className='text-gray-800 text-sm mt-4'>
Eventually I realised three things. <br />
1. I did not need tools, but I needed a super-simple system.<br />
2. The biggest contraint was time.<br />
3. I did not want to pay subscription fees for the tools I use.<br />
4. I wanted to own my data - high level of privacy for journaling is an absolute must.<br />
</p>
<p className='text-gray-800 text-sm mt-4'>

<span className='font-bold'>Now let me tell you what really worked for me:</span> <br /><br />
1. Google Calender - for managing tasks<br />
2. Obsidian - for managing notes<br />
3. Joplin - for journaling<br />
</p>
<p className='text-gray-800 text-sm mt-4'>
That’s it. 
</p><p className='text-gray-800 text-sm mt-4'>
- All these tools are free.<br />
- I manage all my todos in Google Calendar. This goes back to my realisation that time is the biggest constraint. <br />
- Whatever todo I need to add, has to find a slot in my calendar. If it cannot find a slot, it will anyway not get done anyway.<br />
- When I started doing this, the natural optimist in me got a taste of reality. There was only so much that could be done - now I needed to manage my priorities.<br />
- Google calendar is also fantastic at reminders and is cross-platform. Works everywhere.<br />
- Obsidian is free and open source. All the files are synced via. my iCloud account to my devices so I do not have to pay the sync fees.<br />
- I LOVE the network notes feature - it forces a structure and makes me think which ideas are key.<br />
- The hashtags, themes and then the plugin ecosystem mean you can pretty much do anything with Obsidian. I use a fairly minimal features though. Again, simple was my key criteria.<br />
- Joplin is a free and open source notes tool. It syncs my notes to my dropbox. This is my Journaling tool. <br />
- Yes, I could’ve used Obsidian for journaling, but I like how simple Joplin is and I wanted a separate app so I could have the focus and not be easily distracted.<br />
<br />

</p><p className='text-gray-800 text-sm mt-4'>
<span className='font-bold'>Here is how I manage my tasks:</span>< br/>< br/>
Daily:<br/>
1. Look at your calendar in the morning so you know what the day looks like.<br/>
2. End of the day move all tasks that you were not able to finish and move them to another slots.<br/>
3. My meeting notes and preps get pused to Obsidian. “Daily notes” is a great feature to use for this reason.<br/>
<br/>
Weekly:<br/>
1. Every Sunday sit down for a few minutes and plan the next week.<br/>
2. I usually add the one most important thing to get done every day as a “full day” event. That way it stays at the top of the calendar.<br/>
<br/>
You can have your own such rituals as and when it makes sense to you.
<br/>
So that is all.
<br/>
<br/>
            </p>
        </div>

    </div>
  )
}
