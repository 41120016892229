import logo from './logo.svg';
import './App.css';
import HomePage from './pages/HomePage';
import { Routes, Route } from "react-router-dom";
import BlockchainResources from './pages/posts/BlockchainResources';
import MyProductivitySystem from './pages/posts/MyProductivitySystem';
import WorkGrowth from './pages/posts/WorkGrowth';

function App() {
  return (
    <Routes>
        <Route path="/" element={ <HomePage /> } />
        <Route path="/post/blockchain-resources" element={ <BlockchainResources /> } />
        <Route path="/post/productivity-system" element={ <MyProductivitySystem /> } />
        <Route path="/post/work-growth-playbook" element= { <WorkGrowth></WorkGrowth>} ></Route>
    </Routes>
  );
}

export default App;
