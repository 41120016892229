import React from 'react';
import {Helmet} from "react-helmet";

export default function BlockchainResources() {
  return (
    <div className='p-4 bg-slate-50 h-screen font-mono '>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Blockchain Learning Resources</title>
                <link rel="canonical" href="https://pavan.contact/post/blockchain-resources" />
                <link rel="description" href="Personally I am a Blockchain Skeptic but my goal with this guide is to help everyone understand the Blockchain space without the overwhelm - so you can decide for yourself." />
        </Helmet>
        <p className='text-3xl'>Blockchain Resources for Beginners</p>
        <p className='text-xs text-gray-500'>2022</p>
        <div className='mt-4'>
            <p className='text-gray-600 text-sm'>Personally I am a Blockchain Skeptic but my goal with this guide is to help everyone understand the Blockchain space without the overwhelm - so you can decide for yourself.</p>
            
            <hr className='mt-4' />
            <p className='text-gray-800 text-lg font-bold mt-8'>Get the feel of the topic:</p>
            <p className='text-gray-800 text-sm mt-1 underline italic'>
                <a href="https://hbr.org/2017/01/the-truth-about-blockchain">
                The Truth About Blockchain
                </a>
            </p>

            
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://open.spotify.com/episode/4a31nmcocDTvl1S4V3QFNx">
                    [PODCAST] - Hash Power - Ep. 1 - Understanding Blockchains.
                </a>
            </p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://open.spotify.com/episode/6slXSqiBtYzTUqkVOzYLfH">
                    [PODCAST] - Hash Power - Ep. 2 - Investing in Cryptocurrencies.
                </a>
            </p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://open.spotify.com/episode/0tkKfSnTFCNeOh4jCPnM3s">
                    [PODCAST] - Hash Power - Ep. 3 - Funding, Forking and a Creative Future.
                </a>
            </p>

            <p className='text-gray-800 text-lg font-bold mt-8'>Understand Blockchains:</p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://onezero.medium.com/how-does-the-blockchain-work-98c8cd01d2ae">
                How Does the Blockchain Work?
                </a>
            </p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://www.maven11.com/publications/amaras-law-investing-in-blockchain-technology">
                Amara’s Law and a top down view on investing in blockchain
                </a>
            </p>

            <p className='text-gray-800 text-lg font-bold mt-8'>Understand Web3:</p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://docs.google.com/document/d/1SWJw_NTyUvgdB_asRzsnVyKjciW8dZbeqQeUeWsEiQc/edit#">
                Web3 Starter Pack
                </a>
            </p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://trufflesuite.com/blog/how-the-arrival-of-web-3-0-is-transforming-traditional-business-models/">
                How the Arrival of Web 3.0 is Transforming Traditional Business Models
                </a>
            </p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://medium.com/hackernoon/web-3-0-will-be-powered-by-blockchain-technology-stack-626ce3f828c7">
                Web 3.0 Will Be Powered by Blockchain Technology Stack
                </a>
            </p>

            <p className='text-gray-800 text-lg font-bold mt-8'>Understand Smart Contracts:</p>
            
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://trufflesuite.com/blog/how-the-arrival-of-web-3-0-is-transforming-traditional-business-models/">
                Smart Contracts
                </a>
            </p>

            <p className='text-gray-800 text-lg font-bold mt-8'>Understand Cryptocurrencies:</p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://www.youtube.com/watch?v=VYWc9dFqROI">
                [Video] Explain Crypto to complete beginners
                </a>
            </p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://www.coindesk.com/markets/2017/08/19/making-sense-of-cryptoeconomics/">
                Making sense of Cryptocurrencies
                </a>
            </p>

            <p className='text-gray-800 text-lg font-bold mt-8'>Understand DeFi:</p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://www.maven11.com/publications/the-future-of-finance-decentralized-finance">
                Future of Finance - DeFi.
                </a>
            </p>
            
            <p className='text-gray-800 text-lg font-bold mt-8'>Understand NFTs:</p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://www.tomhirst.com/getting-started-with-nfts/">
                Ultimate guide to understanding NFTs.
                </a>
            </p>
            
            <p className='text-gray-800 text-lg font-bold mt-8'>Understand DApps:</p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://hackernoon.com/what-are-decentralized-applications-dapps-explained-with-examples-7ff8f2c4a460">
                What Are Decentralized Applications (dApps)? — Explained With Examples.
                </a>
            </p>

            <p className='text-gray-800 text-lg font-bold mt-8'>Understand DAOs:</p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://linda.mirror.xyz/Vh8K4leCGEO06_qSGx-vS5lvgUqhqkCz9ut81WwCP2o">
                A beginner’s guide to DAOs
                </a>
            </p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://p.mirror.xyz/cVN3KOss0uqpZwxHQKtC4Syvn1RfXaxofFKHJuKLWS4">
                Community DAOs
                </a>
            </p>

            <p className='text-gray-800 text-lg font-bold mt-8'>Understand Metaverse:</p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://www.matthewball.vc/all/forwardtothemetaverseprimer">
                Framework for the Metaverse
                </a>
            </p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://www.matthewball.vc/all/hardwaremetaverse">
                Hardware and the Metaverse
                </a>
            </p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://www.matthewball.vc/all/networkingmetaverse">
                Networking and the Metaverse
                </a>
            </p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://www.matthewball.vc/all/computemetaverse">
                Compute and the Metaverse
                </a>
            </p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://www.matthewball.vc/all/virtualplatformsmetaverse">
                Virtual Platforms and the Metaverse
                </a>
            </p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://www.matthewball.vc/all/interchangemetaverse">
                Interchange Tools + Standards and the Metaverse
                </a>
            </p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://www.matthewball.vc/all/metaversepayments">
                Payments, Payment Rails, and Blockchains, and the Metaverse
                </a>
            </p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://www.matthewball.vc/all/csametaverse">
                Content, Services, and Asset Businesses in the Metaverse
                </a>
            </p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://www.notboring.co/p/the-value-chain-of-the-open-metaverse">
                The Value Chain of the Open Metaverse
                </a>
            </p>
            

            <p className='text-gray-800 text-lg font-bold mt-8'>Guides:</p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://patrickhq.medium.com/how-to-launch-and-market-your-own-cryptocurrency-ico-33dbebecf382">
                How To Launch And Market Your Own Cryptocurrency/ICO
                </a>
            </p>


            <p className='text-gray-800 text-lg font-bold mt-8'>Criticism and Challenges:</p>
            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://101blockchains.com/blockchain-adoption-challenges/">
                Top Blockchain Adoption Challenges: What Are They?
                </a>
            </p>

            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://mitsloan.mit.edu/ideas-made-to-matter/risks-and-unintended-consequences-blockchain">
                The risks and unintended consequences of blockchain
                </a>
            </p>

            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://osf.io/wzf85?view_only=a10581ae9a804aa197ac39ebbba05766">
                Cryptoeconomics as a Limitation on Governance
                </a>
            </p>

            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://vitalik.ca/general/2021/09/26/limits.html">
                [Response to the above] On Nathan Schneider on the limits of cryptoeconomics
                </a>
            </p>

            <p className='text-gray-800 text-sm mt-2 underline italic'>
                <a href="https://www.youtube.com/watch?v=4CdO0olVfAA">
                [Video] Scaling Blockchains
                </a>
            </p>
            
            
            
        </div>
    </div>
  )
}
